import React, { useEffect, useState } from "react";
import { IoCheckmarkDoneOutline } from "react-icons/io5";
import { FaArrowUp } from "react-icons/fa6";
import { FaArrowDown } from "react-icons/fa6";
import { FaCaretDown } from "react-icons/fa";
import { BsQuestionSquare } from "react-icons/bs";
import AppTestimonials from "../../../components/AppLandingPageComponents/landingPageComponents/AppTestimonials";
import SparkleBackground from "../../../components/Animations/SparkleBackground";
import AppSectionHeader from "../../../components/AppLandingPageComponents/reUsableComponents/AppSectionHeader";
import AppBannerPage from "../../../components/AppLandingPageComponents/landingPageComponents/AppBannerPage";
import { useNavigate } from "react-router-dom";
import { appSubmitForm } from "../../../routes/routes";
import DropdownComponent from "./Dropdown";

const PricingPage = () => {

  const parallelRuns = [
    {
      numberOfRuns: 2,
      price: 250,
      cloudRuns: 2000
    },
    {
      numberOfRuns: 3,
      price: 300,
      cloudRuns: 3000
    },
    {
      numberOfRuns: 4,
      price: 350,
      cloudRuns: 4000
    },
    {
      numberOfRuns: 5,
      price: 400,
      cloudRuns: 5000
    },
    {
      numberOfRuns: 8,
      price: 550,
      cloudRuns: 6500
    },
    {
      numberOfRuns: 10,
      price: 650,
      cloudRuns: 8500
    },
  ];
  const [ parallelRunsObj, setParallelRunsObj ] = useState(parallelRuns[0]);

  useEffect(() => {
    document.title = "Plans & Pricing | AutoFlow Studio";
  }, [])

  const navigate = useNavigate();
  const handlePriceSubmit = (priceTitle) => {
    const data = {
      title: "Sales",
      description: "Fill the form below to get exclusive price from our sales team",
      src: `https://tally.so/embed/3EXQ5X?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1?tier=${priceTitle}`,
    };
    navigate(appSubmitForm, { state: { data: data } });
  };
  return (
    <div>
      <div className="relative overflow-hidden">
        <div className="relative mx-auto max-w-6xl lg:max-w-7xl px-4 sm:px-6">
          <div className="pb-24 pt-32">
            <SparkleBackground>
              <AppSectionHeader
                title="Pricing"
                mainTitle="Affordable Plans for Everyone"
                description="We offer paid plans with additional features and support."
              />
            </SparkleBackground>

            <div
              className="mx-auto mt-16 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:mt-20 lg:gap-y-0 lg:max-w-none
            lg:grid-cols-4"
            >
              {/* card0 */}
              <div className="bg-black/40 sm:mx-8 lg:mx-0 rounded-t-3xl  lg:rounded-bl-3xl lg:rounded-tr-none rounded-3xl p-8 ring-1 ring-zinc-800 backdrop-blur-md">
                <h4 className="text-zinc-200 text-3xl font-display font-semibold leading-loose">
                  Free
                </h4>
                <div className="text-zinc-200 mt-4 flex items-baseline gap-x-2">
                  <h1 className="text-5xl font-semibold tracking-tight">
                    $0
                  </h1>
                  <span className="font-heading">/ month</span>
                </div>
                <p className="text-zinc-400 mt-6 text-sm">
                  For testing simple web apps and learn about the platform
                </p>
                <ul className="text-zinc-400 mt-8 space-y-3 text-sm font-display">
                  <li className="flex font-semibold items-center gap-x-3">
                    What's included:
                  </li>
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline /> 1 User
                  </li>
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline />
                    Unlimited Tests
                  </li>
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline />
                    Unlimited Test Suites
                  </li>
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline />
                    Unlimited Local Test Runs
                  </li>
                  <div class="tooltip" data-tip="Get a screenshot of every test step in your test runs">
                    <li className="flex items-center gap-x-3">
                      <IoCheckmarkDoneOutline />
                      Screenshots <BsQuestionSquare />
                    </li>
                  </div>
                  <br/>
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline />
                    7 Days of Test Run History
                  </li>
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline />
                    Community & Email Support
                  </li>
                </ul>
                <a
                  href="/downloads"
                  className="relative mt-8 inline-flex w-full flex-shrink-0 items-center justify-center rounded-xl border px-3 py-2 font-heading text-sm font-medium transition border-zinc-500/50 bg-zinc-600/10 text-zinc-400 hover:border-zinc-500/80"
                >
                  Download App
                </a>
              </div>
              {/* card 2 */}
              <div className="bg-black/40 sm:mx-8 lg:mx-0 rounded-t-3xl  lg:rounded-bl-3xl lg:rounded-tr-none rounded-3xl px-8 py-16 ring-1 ring-zinc-800 backdrop-blur-md">
                <h4 className="text-zinc-200 text-3xl font-display font-semibold leading-loose">
                  Starter
                </h4>
                <div className="text-zinc-200 mt-4 flex items-baseline gap-x-2">
                  <h1 className="text-5xl font-semibold tracking-tight">
                    $99
                  </h1>
                  <span className="font-heading">/ month</span>
                </div>
                <p className="text-zinc-400 mt-6 text-sm">
                  For testing simple web apps and learn about the platform
                </p>
                <ul className="text-zinc-400 mt-8 space-y-3 text-sm font-display">
                  <li className="flex font-semibold items-center gap-x-3">
                    Everything in FREE plus:
                  </li>
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline />
                    100 Cloud Runs
                  </li>
                  <div class="tooltip" data-tip="Get a video recording of every test run!">
                    <li className="flex items-center gap-x-3">
                      <IoCheckmarkDoneOutline />
                      Video Recordings <BsQuestionSquare />
                    </li>
                  </div>
                  <br/>
                  <div class="tooltip" data-tip="Schedule your tests to run after a certain interval">
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline /> Scheduled Runs <BsQuestionSquare />
                  </li>
                  </div>
                  <br/>
                  <div class="tooltip" data-tip="Get instant reports and notifications of your test runs">
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline /> Slack Notifications <BsQuestionSquare />
                  </li>
                  </div>
                  <br/>
                  <div class="tooltip" data-tip="Get instant email to all stakeholders">
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline /> Email Notifications <BsQuestionSquare />
                  </li>
                  </div>
                  <br/>
                  <div class="tooltip" data-tip="Seamlessly switch between environments to test multiple environments">
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline /> Environments <BsQuestionSquare />
                  </li>
                  </div>
                  <br/>
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline />
                    30 Days of Test History
                  </li>
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline />
                    Community & Email Support
                  </li>
                </ul>
                <button
                  onClick={() => handlePriceSubmit("Basic")}
                  className="relative mt-8 inline-flex w-full flex-shrink-0 items-center justify-center rounded-xl border px-3 py-2 font-heading text-sm font-medium transition border-zinc-500/50 bg-zinc-600/10 text-zinc-400 hover:border-zinc-500/80"
                >
                  Start free 14-day trial
                </button>
              </div>
              {/* card 3 */}
              <div className="relative bg-primary-color-light/90 rounded-3xl p-8 py-6 ring-zinc-800 backdrop-blur-md z-20  border-[5px] border-[#F29D20] shadow-[0px_0px_15px_-3px] shadow-[#F29D20]">
                <div className="absolute top-6 right-6 badge badge-warning badge-md gap-2 font-heading font-medium py-1 h-fit">
                  ✦ Most popular
                </div>
                <h4 className="text-zinc-950 text-3xl font-display font-semibold leading-loose">
                  Pro
                </h4>
                <div className="mt-4 flex items-baseline gap-x-2">
                  <h1 className="text-zinc-950 text-5xl font-semibold tracking-tight">
                    ${ parallelRunsObj.price }
                  </h1>
                  <span className="text-zinc-950 font-heading">/ month</span>
                </div>
                <p className="text-zinc-950 mt-6 text-sm">
                  Everything you need to get started with testing, perfect for
                  individuals and small teams.
                </p>
                <ul className="text-zinc-950 mt-8 space-y-3 text-sm font-display">
                  <li className="flex font-semibold items-center gap-x-3">
                    Everything in STARTER plus:
                  </li>
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline /> 5 Users
                  </li>
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline /> { parallelRunsObj.cloudRuns } Cloud Test Runs
                  </li>
                  <DropdownComponent parallelRuns={parallelRuns} setParallelRunsObj={setParallelRunsObj} parallelRunsObj={parallelRunsObj} />
                  <br></br>
                  <div class="tooltip" data-tip="One-click CI/CD integration. We even help with the integrations!">
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline /> CI/CD Integrations <BsQuestionSquare />
                  </li>
                  </div>
                  <br/>
                  <div class="tooltip" data-tip="Get temporary emails and AI powered email detection for testing sign up flows">
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline /> Inbox for e-mail testing <BsQuestionSquare />
                  </li>
                  </div>
                  <br/>
                  <div class="tooltip" data-tip="Webhooks to customize the alerts you receive">
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline /> Webhooks <BsQuestionSquare />
                  </li>
                  </div>
                  <br/>
                  <div class="tooltip" data-tip="Get exclusive API access for all your testing needs">
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline /> API Access <BsQuestionSquare />
                  </li>
                  </div>
                  <br/>
                  <div class="tooltip" data-tip="Gain fine-tuned control over your organisation">
                  <li className="flex items-center gap-x-3 text-left">
                    <IoCheckmarkDoneOutline /> Role-based Access Control <BsQuestionSquare />
                  </li>
                  </div>
                  <br/>
                  <div class="tooltip" data-tip="increase productivity with AutoFlow AI suggestions">
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline />
                    500 AI Suggestions <BsQuestionSquare />
                  </li>
                  </div>
                  <br/>
                  <div class="tooltip" data-tip="Add robust and self-healing AI steps to your test cases">
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline />
                    1000 AI Steps <BsQuestionSquare />
                  </li>
                  </div>
                  <br/>
                  <div class="tooltip" data-tip="Call or message us anytime for immediate problem resolution">
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline />
                    24/7 Support <BsQuestionSquare />
                  </li>
                  </div>
                </ul>
                <button
                  onClick={() => handlePriceSubmit("Basic")}
                  className="relative mt-8 inline-flex w-full flex-shrink-0 items-center justify-center rounded-xl border px-3 py-2 font-heading text-sm font-medium transition border-zinc-500/50 bg-zinc-600/10 text-black font-bold hover:border-zinc-500/80"
                >
                  Subscribe Now
                </button>
              </div>

              {/* card 4 */}
              <div className="bg-black/40 sm:mx-8 lg:mx-0 rounded-t-3xl  lg:rounded-br-3xl lg:rounded-tl-none rounded-3xl px-8 py-14 ring-zinc-800 backdrop-blur-md">
                <div className="flex flex-row items-center justify-between">
                  <h1 className="text-zinc-200 text-3xl font-display font-semibold leading-loose">
                    Enterprise
                  </h1>
                </div>
                <div className="text-zinc-200 mt-4 flex items-baseline gap-x-2">
                  <h1 className="text-5xl font-semibold tracking-tight">
                    Talk to Us
                  </h1>
                  {/* <span className="text-zinc-600 font-heading">/ month</span> */}
                </div>

                <p className="text-zinc-400 mt-6 text-sm">
                  Crafted for teams and enterprises seeking an unparalleled,
                  privacy-friendly testing experience.
                </p>
                <ul className="text-zinc-400 mt-8 space-y-3 text-sm font-display">
                  <li className="flex font-semibold items-center gap-x-3">
                    Everything in BASIC plus:
                  </li>
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline /> Up to 48x faster testing via parallel test execution
                  </li>
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline /> Custom Seat Numbers
                  </li>
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline />
                    Up to Unlimited test history
                  </li>
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline />
                    Exclusive Customer Support
                  </li>
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline />
                    On-Premise Deployment
                  </li>
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline />
                    Customization Support for specific use-cases
                  </li>
                </ul>
                <button
                  onClick={() => handlePriceSubmit("Pro")}
                  className="relative mt-8 inline-flex w-full flex-shrink-0 items-center justify-center rounded-xl border px-3 py-2 font-heading text-sm font-medium transition border-zinc-500/50 bg-zinc-600/10 text-zinc-400 hover:border-zinc-500/80"
                >
                  Contact Sales
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AppTestimonials />
      <AppBannerPage />
    </div>
  );
};

export default PricingPage;
