import React, { useState } from 'react';
import { IoCheckmarkDoneOutline } from 'react-icons/io5';
import { FaCaretDown } from 'react-icons/fa';

const DropdownComponent = ({ parallelRuns, setParallelRunsObj, parallelRunsObj }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleItemClick = (item) => {
    setParallelRunsObj(item);
    setIsDropdownOpen(false); // Close the dropdown
  };

  return (
    <li className="dropdown dropdown-hover">
      <div
        tabIndex={0}
        role="button"
        className="flex items-center gap-x-3 cursor-pointer relative"
        onClick={toggleDropdown}
      >
        <IoCheckmarkDoneOutline />
        <div className="flex p-1 border border-zinc-700/50 gap-x-3 rounded-md items-center bg-primary-color-light">
          <span>{parallelRunsObj.numberOfRuns} Parallel Runs</span>
          <FaCaretDown className="-ml-2" />
        </div>
      </div>
      {isDropdownOpen && (
        <ul
          tabIndex={0}
          className="ml-6 dropdown-content w-full z-50 bg-primary-color-light shadow-lg rounded-md text-xs p-[6px]"
        >
          {parallelRuns.map((item) => (
            <li
              key={item.numberOfRuns}
              onClick={() => handleItemClick(item)}
              className="cursor-pointer hover:bg-zinc-700/20 rounded-[5px] py-1 px-[5px] w-full font-medium"
            >
              {item.numberOfRuns} Parallel Runs
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

export default DropdownComponent;