import React, { useEffect, useState } from "react";
import { FaBarsProgress, FaDiscord, FaGithub } from "react-icons/fa6";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { TbLivePhoto } from "react-icons/tb";
import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";
import { sdkAccount, sdkHome, sdkLogin, sdkSignUp } from "../../../../../routes/routes";
import { logo } from "../../assets";
import MobileNav from "./MobileNav";

const NavBar = ({ sessionExists }) => {
  const [scrolling, setScrolling] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [activeSection, setActiveSection] = useState(null);
  const [openSdk, setOpenSdk] = useState(true);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }

    const sections = ["land", "examples", "features", "pricing"];
    const offset = window.innerHeight / 2;

    for (const section of sections) {
      const element = document.getElementById(section);
      if (
        element.offsetTop - offset <= window.scrollY &&
        element.offsetTop + element.offsetHeight - offset > window.scrollY
      ) {
        setActiveSection(section);
        break;
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <nav
      className={`fixed w-full ${scrolling ? "bg-primary-color-dark bg-opacity-90" : "bg-transparent"
        } transition-all duration-300 ease-in-out z-[999]`}
    >
      {Boolean(openSdk) && (
        <div className="text-center bg-primary-color/80 relative p-1">
          <p className="flex flex-row items-center justify-center gap-1 font-primary font-bold text-xs md:text-base">
            The Autoflow Library is now
            <NavLink
              to={sdkSignUp}
              className="text-green-800 animate-pulse pl-1 md:text-xl underline decoration-1 underline-offset-2"
            >
              Live!
            </NavLink>
            <NavLink
              to={sdkSignUp}
              className="text-green-800 md:text-xl animate-pulse"
            >
              <TbLivePhoto />
            </NavLink>
            <NavLink
              to={sdkSignUp}
              className="underline md:ml-5 flex flex-row items-center gap-1"
            >
              {`Click here`}
            </NavLink>
            to get access.
          </p>
          <button
            className="absolute top-1 right-1 sm:right-4"
            onClick={() => setOpenSdk(false)}
          >
            <IoIosCloseCircleOutline className="md:text-2xl" />
          </button>
        </div>
      )}
      <div className="container mx-auto -my-10 sm:-my-14 flex items-center justify-between">
        {/* Logo */}
        <div className="relative">
          <NavLink to={sdkHome}>
            <img src={logo} alt="Logo" className="h-40 sm:h-52" />
            <div class="badge absolute bg-primary-color lg:bottom-[69px] md:bottom-[69px] sm:bottom-[48px] bottom-[48px] font-semibold right-0">SDK</div>
          </NavLink>
        </div>

        {/* Navigation buttons */}
        <div className="hidden md:flex space-x-4 -mr-24">
          <Link
            to="land"
            smooth={true}
            duration={500}
            className={
              activeSection === "land" ? "text-yellow-600" : "text-white"
            }
          >
            <button className="btn btn-ghost text-lg font-primary font-bold">
              Home
            </button>
          </Link>
          <Link
            to="examples"
            smooth={true}
            duration={500}
            className={
              activeSection === "examples" ? "text-yellow-600" : "text-white"
            }
          >
            <button className="btn  btn-ghost text-lg font-primary font-bold">
              Examples
            </button>
          </Link>
          <Link
            to="features"
            smooth={true}
            duration={500}
            className={
              activeSection === "features" ? "text-yellow-600" : "text-white"
            }
          >
            <button className="btn  btn-ghost text-lg font-primary font-bold">
              Features
            </button>
          </Link>
          <Link
            to="pricing"
            smooth={true}
            duration={500}
            className={
              activeSection === "pricing" ? "text-yellow-600" : "text-white"
            }
          >
            <button className="btn  btn-ghost text-lg font-primary font-bold">
              Pricing
            </button>
          </Link>
          <a
            href="https://github.com/AutoFlowLabs/autoflow"
            target="_blank"
            rel="noreferrer"
          >
            <button className="btn  btn-ghost text-white text-lg font-primary font-bold">
              Docs
            </button>
          </a>
        </div>

        {/* Sign In button */}
        <div className="hidden md:flex flex-row items-center gap-2">
          <a
            href="https://discord.gg/FHXJRkYqEQ"
            target="_blank"
            rel="noreferrer"
          >
            <button className="btn btn-ghost text-white font-primary font-bold">
              <FaDiscord />
              Discord
            </button>{" "}
          </a>
          <a
            href="https://github.com/AutoFlowLabs/autoflow"
            target="_blank"
            rel="noreferrer"
          >
            <button className="btn btn-ghost text-white font-primary font-bold">
              <FaGithub />
              Github
            </button>{" "}
          </a>
          {sessionExists ? (
            <NavLink to={sdkAccount}>
              <button className="btn btn-warning px-4 py-2 text-secondary-color-dark font-secondary">
                Dashboard
              </button>
            </NavLink>
          ) : (
            <NavLink to={sdkLogin}>
              <button className="btn btn-warning px-4 py-2 text-secondary-color-dark font-secondary">
                Sign In
              </button>
            </NavLink>
          )}
        </div>

        <div className="flex md:hidden">
          <button
            className="btn btn-ghost"
            onClick={() => {
              setOpenMenu(true);
            }}
          >
            <FaBarsProgress className="text-2xl text-white" />
          </button>
          <MobileNav openMenu={openMenu} setOpenMenu={setOpenMenu} sessionExists={sessionExists} />
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
