import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { getBillingDetails, getUsageData, profile1 } from "../../../api/services/apiService";
import { sdkApiKeys } from "../../../routes/routes";
import { formatDate } from "../../../utils/utlilityFunctions/formatDate";
import { formatNextMonth } from "../../../utils/utlilityFunctions/formatNextMonth";
import BarChart from "../Charts/BarChart";
import LineChart from "../Charts/LineChart";
 

function AccountInformation() {
  const [chart, setChart] = useState("lineChart"); // Initial state for the checkbox
  const [days, setDays] = useState([]);
  const [userData, setUserData] = useState();
  const [invocations, setInvocations] = useState([]);
  const [billingData, setBillingData] = useState();
  const [totalInvocations, setTotalInvocations] = useState(0);
  const [nextMonth, setNextMonth] = useState("");

  const isMounted = useRef(true);

  useEffect(() => {
    console.log('useEffect called'); // This will log every time the useEffect hook runs
    profile1()
      .then((res) => {
        console.log('Profile response:', res); // This will log the response from the profile function
        res && setUserData(res.ProfileData);
      })
      .catch((err) => {
        console.error('Profile error:', err); // This will log any errors from the profile function
      });
  }, []);


  useEffect(() => {
    getBillingDetails()
      .then((res) => {
        setBillingData(res);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      getUsageData()
        .then((res) => {
          let data = res?.usageData;
          setTotalInvocations(res?.totalInvocations);
          if (!data) {
            setInvocations(0);
          } else {
            // TEMPORARY FIX
            let dateArr = [];
            let invocationsArr = [];
            let dateSplit = data[0]?.date?.split("-");
            let currentMonth = parseInt(dateSplit[1]);
            let resetValue = currentMonth < 12 ? currentMonth + 1 : 1;
            let resetMonth = getMonthName(resetValue);
            setNextMonth(`01-${resetMonth}-${dateSplit[0]}`);
            let daysInMonth = getDaysInMonth(dateSplit[1], dateSplit[2]);
            for (let index = 0; index <= daysInMonth; index++) {
              const element = data.find(
                (item) => parseInt(item?.date.slice(-2), 10) === index
              );
              let dateValue = parseInt(element?.date.slice(-2), 10);
              if (index > 0) {
                dateArr.push(index);
              }
              if (element) {
                invocationsArr[dateValue - 1] = parseInt(element?.invocations);
              } else {
                invocationsArr.push(0);
              }
            }
            setDays(dateArr);
            setInvocations(invocationsArr);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      isMounted.current = false;
    }
  }, []);

  function getMonthName(monthNumber) {
    var date = new Date(2000, monthNumber - 1, 1);
    var monthName = date.toLocaleString("en-US", { month: "short" });
    return monthName;
  }

  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };
  const handleCheckboxChange = (clickChart) => {
    setChart(clickChart);
  };

  
  return (
    <div className="flex flex-col space-y-10 mt-10 bg-black pt-5 px-2 sm:px-10 w-full font-secondary">
      {invocations === 0 ? (
        <div className="card w-full bg-black opacity-80 border border-[#1b1b1b] shadow-sm hover:shadow-[#7b5f2c]">
          <div className="card-body">
            <h2 className="card-title text-xs sm:text-xl md:text-3xl  font-primary font-bold text-white">
              Hey
              <span className="text-primary-color">
                {userData && userData?.name},
              </span>
            </h2>
            <p className=" gap-x-2 font-secondary mt-2 text-white/60 text-[10px] sm:text-lg">
              It seems you've not yet started using the service. To get started,
              go to the{" "}
              <Link
                to={sdkApiKeys}
                className="  underline decoration-2 underline-offset-4 font-bold sm:text-lg text-white/90 decoration-primary-color"
              >
                API section
              </Link>{" "}
              to generate an API key.
            </p>
          </div>
        </div>
      ) : (
        <div className="card w-full bg-black opacity-80 border border-[#1b1b1b] shadow-sm hover:shadow-[#7b5f2c]">
          <div className="card-body">
            <h2 className="card-title text-xs sm:text-xl md:text-3xl  font-primary font-bold text-white">
              Welcome back,{" "}
              <span className="text-primary-color">
                {" "}
                {userData && userData?.name}!
              </span>
            </h2>
            <p className="font-secondary mt-2 text-white/60 text-[10px] sm:text-lg">
              Your command center awaits – dive into the heart of your
              AI-powered testing with AutoFlow's intuitive dashboard.
            </p>
          </div>
        </div>
      )}
      <div className="w-full flex flex-col sm:flex-row gap-1 sm:gap-5 text-center">
        <div className="card sm:w-1/2 bg-black border border-[#1b1b1b] shadow-sm hover:shadow-[#7b5f2c]">
          <div className="card-body">
            <p className="text-white font-primary font-bold text-[10px] sm:text-base md:text-xl">
              Current Month's Invocation
            </p>
            <h2 className="font-primary font-semibold text-white card-title flex justify-center text-[10px] sm:text-xl leading-none">
              <span className="text-primary-color">
                {totalInvocations ? totalInvocations : "0"}
              </span>{" "}
              of{" "}
              <span className="text-primary-color">
                {userData
                  ? userData?.membership_type === "FREE"
                    ? "1000"
                    : "5000"
                  : "1000"}
              </span>{" "}
              requests
            </h2>
          </div>
        </div>
        <div className="card sm:w-1/2 bg-black border border-[#1b1b1b]  shadow-sm hover:shadow-[#7b5f2c]">
          <div className="card-body">
            <p className="text-white font-primary font-bold text-[10px] sm:text-base md:text-xl">
              Monthly Cycle Reset
            </p>
            <h2 className="card-title font-primary font-semibold text-primary-color flex justify-center text-[10px] sm:text-xl leading-none">
              {billingData
                ? billingData?.subscription?.next_billing_at
                  ? formatDate(billingData.subscription.next_billing_at)
                  : formatNextMonth
                : formatNextMonth}
            </h2>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center font-primary w-full whitespace-nowrap pt-10">
        <div className="font-primary font-bold sm:text-xl capitalize text-primary-color">
          usage overview
        </div>
        <div className="flex justify-between w-full">
          <div className="text-white bg-black w-full h-full">
            {chart === "barChart" ? (
              <BarChart days={days} invocations={invocations} />
            ) : (
              <LineChart days={days} invocations={invocations} />
            )}

            <div className="flex justify-end items-center space-x-4">
              <div className="form-control">
                <label className="label cursor-pointer space-x-2">
                  <span
                    className={`label-text text-xs sm:text-base ${
                      chart === "lineChart"
                        ? "text-primary-color font-primary font-bold"
                        : "text-white"
                    }`}
                  >
                    Line Chart
                  </span>
                  <input
                    type="checkbox"
                    checked={chart === "lineChart"}
                    onClick={() => handleCheckboxChange("lineChart")}
                    className="checkbox checkbox-xs sm:checkbox-md checkbox-warning border-gray-300"
                  />
                </label>
              </div>
              <div className="form-control">
                <label className="label cursor-pointer space-x-2">
                  <span
                    className={`label-text text-xs sm:text-base ${
                      chart === "barChart"
                        ? "text-primary-color font-primary font-bold"
                        : "text-white"
                    }`}
                  >
                    Bar Chart
                  </span>
                  <input
                    type="checkbox"
                    checked={chart === "barChart"}
                    onClick={() => handleCheckboxChange("barChart")}
                    className="checkbox checkbox-xs sm:checkbox-md border checkbox-warning border-gray-300"
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountInformation;
