import React,{useState,useEffect} from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useNavigate,
} from "react-router-dom";
import AppLandingPageLayout from "./layout/AppLandingPage/AppLandingPageLayout";
import SessionReact, { getUserId } from "supertokens-auth-react/recipe/session";
import DownloadsPage from "./pages/AppLandingPage/DownloadsPage";
import HomePage from "./pages/AppLandingPage/HomePage";
import PricingPage from "./pages/AppLandingPage/PricingPage";
import PrivacyPolicy from "./components/SdkLandingPageComponents/landingpage/components/PrivacyPolicy";
import ReturnPolicy from "./components/SdkLandingPageComponents/landingpage/components/ReturnPolicy";
import TermsOfService from "./components/SdkLandingPageComponents/landingpage/components/TermsOfService";
import NotFound from "./pages/NotFound";
import SDKLayout from "./layout/SDKLayout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SubmitForm from "./components/AppLandingPageComponents/tallyForms/Form";
import ForQA from "./components/AppLandingPageComponents/landingPageComponents/AppCards/ForQA";
import ForDevelopers from "./components/AppLandingPageComponents/landingPageComponents/AppCards/ForDevelopers";
import ForProductManagers from "./components/AppLandingPageComponents/landingPageComponents/AppCards/ForProductManagers";
import Login from "./auth/sdkLandingPage/login";
import {
  appAccount,
  sdkChangePwd,
  appLogin,
  sdkVerifyEmail,
} from "./routes/routes";
import Session from "supertokens-web-js/recipe/session";
import { getAxiosInstance } from "../src/api/services/apiService";
import SdkDashboardLayout from "./layout/SdkLandingPage/SdkDashboardLayout";
import SuperTokens from "supertokens-auth-react";
import ThirdPartyEmailPassword from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import Billing from "./components/SdkLandingPageComponents/billing";
import Profile from "./components/SdkLandingPageComponents/profile";
import Signup from "./auth/sdkLandingPage/signup";
import FeaturesPage from "./components/AppLandingPageComponents/landingPageComponents/AppCards/FeaturesPage";
import WhyAutoFlow from "./components/AppLandingPageComponents/landingPageComponents/AppCards/WhyAutoFlow";
import EmailConfirmation from "./auth/sdkLandingPage/EmailConfirmation";

SuperTokens.init({
  appInfo: {
    appName: "autoflow",
    apiDomain: process.env.REACT_APP_AUTH_BASE_URL,
    websiteDomain: process.env.REACT_APP_WEBSITE_DOMAIN,
    apiBasePath: "/auth",
    websiteBasePath: "/auth",
  },
  recipeList: [
    ThirdPartyEmailPassword.init(),
    process.env.REACT_APP_WEBSITE_DOMAIN === "http://localhost:3000"
      ? SessionReact.init()
      : SessionReact.init({ sessionTokenFrontendDomain: ".autoflow.tools" }),
  ],
});

const App = () => {

  getAxiosInstance();
  const [userId, setUserId] = useState("");
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [doesSessionExistState, setDoesSessionExistState] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        const id = await getUserId();
        setUserId(id);
      } catch (error) {
        console.error("Error fetching user ID:", error);
      }
    })();

    (async () => {
      if (await Session.doesSessionExist()) {
        setDoesSessionExistState(true);
      }
    })();
  }, []);

  const PrivateRoute = ({ children }) => {
    const navigate = useNavigate();
    const sessionExists = doesSessionExistState;

    useEffect(() => {
      (async () => {
        if (sessionExists) {
          if (isEmailVerified) {
            return true;
          } else {
            navigate(sdkVerifyEmail);
          }
        } else {
          // console.log("Session does not exist, redirecting to login");
          navigate(appLogin);
        }
      })();
    }, [sessionExists]);

    return sessionExists ? children : null;
  };

  const PublicRoute = ({ children }) => {
    const navigate = useNavigate();
    const sessionExists = doesSessionExistState;
  
    useEffect(() => {
      (async () => {
        if (sessionExists) {
          // console.log("Session exists, redirecting to account");
          navigate(appAccount);
        }
      })();
    }, [navigate, sessionExists]);
  
    return !sessionExists ? children : null;
  };

  // check is email verified
  useEffect(() => {
    const checkisEmailVerified = async () => {
      if (await Session.doesSessionExist()) {
        try {
          let accessTokenPayload =
            await Session.getAccessTokenPayloadSecurely();
          let isEmailVerified = accessTokenPayload["st-ev"]["v"];
          console.log(isEmailVerified, "is Email verified from app.js");
          setIsEmailVerified(isEmailVerified);
          return isEmailVerified;
        } catch (error) {
          console.error(
            "Error occurred while getting email verification status:",
            error
          );
          return false;
        }
      } else {
        return false;
      }
    };

    checkisEmailVerified();
  }, []);

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<AppLandingPageLayout />}>
            <Route path="downloads" Component={DownloadsPage} />
            <Route path="pricing" Component={PricingPage} />
            <Route path="contact-us" Component={SubmitForm} />
            <Route path="privacy" element={<PrivacyPolicy />} />
            <Route path="terms" element={<TermsOfService />} />
            <Route path="return-policy" element={<ReturnPolicy />} />
            <Route path="for-QA" element={<ForQA />} />
            <Route path="features" element={<FeaturesPage />} />
            <Route path="whyAutoFlow" element={<WhyAutoFlow />} />
            <Route path="for-Dev" element={<ForDevelopers />} />
            <Route path="for-Product-manager" element={<ForProductManagers />} />
            <Route index Component={HomePage} />
          </Route>
          <Route path="/sdk/*" element={<SDKLayout />} />
          <Route
            path="/login"
            element={
              <PublicRoute>
                <Login logFrom={'app'} />
              </PublicRoute>
            }
          />
           <Route
              path="/signup"
              element={
                <PublicRoute>
                  <Signup logFrom={'app'} />
                </PublicRoute>
              }
            />
            <Route path="/verify-email" element={<EmailConfirmation logFrom={'app'} />} />
          <Route
            path="/account"
            element={
              <PrivateRoute>
                <SdkDashboardLayout logFrom={'app'} isEmailVerified={isEmailVerified} />
              </PrivateRoute>
            }
          >
            <Route path="billing" element={<Billing />} />
            <Route path="profile" element={<Profile />} />
          </Route>
          
          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </Router>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={6000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          toastStyle={{
            width: "auto",
            fontFamily: "Inter Variable",
            fontWeight: "bold",
          }}
        />
      </div>
    </>
  );
};

export default App;
