import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaGithub } from "react-icons/fa";
import { IoLogoGoogle } from "react-icons/io5";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  emailPasswordSignIn,
  getAuthorisationURLWithQueryParamsAndSetState,
} from "supertokens-web-js/recipe/thirdpartyemailpassword";
import { appAccount, appBilling, appSignUp, sdkAccount, sdkForgotPwd, sdkHome, sdkInfoPage, sdkSignUp } from "../../../routes/routes";
import { logo_square_without_name } from "../../../assets";
import Input from "../components/Input";
import PasswordInput from "../components/PasswordInput";

function Login({logFrom}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const verifyEmailResponse = async () => {
      if (location.search.includes("?ref=email-verification-success")) {
        toast.success(
          "Your email is verified successfully. Please login to continue"
        );
      }
      if (location.search.includes("?ref=email-verification-success-app")) {
       navigate(sdkInfoPage)
      }
    };

    verifyEmailResponse();
  }, [location]);

  const locationData = location.state;

  useEffect(() => {
    if (locationData && locationData.source === "third party") {
      toast.error(locationData?.message, {
        autoClose: false,
      });
      const timeout = setTimeout(() => {
        navigate(location.pathname, { replace: true, state: {} });
      }, 1000);
      return () => clearTimeout(timeout);
    } else {
      toast.success(locationData && locationData?.message);
      const timeout = setTimeout(() => {
        navigate(location.pathname, { replace: true, state: {} });
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [locationData, navigate, location]);

  async function signUpGoogle() {
    // console.log("signUpGoogle button clicked");
    try {
      const authUrl = await getAuthorisationURLWithQueryParamsAndSetState({
        thirdPartyId: "google",
        frontendRedirectURI: `${process.env.REACT_APP_WEBSITE_DOMAIN}/sdk/auth/callback/google`,
      });
      // console.log("Redirecting to auth URL:", authUrl);
      window.location.assign(authUrl);
    } catch (err) {
      console.error("Error in signUpGoogle:", err);
      if (err.isSuperTokensGeneralError === true) {
        toast.error(err.message);
      } else {
        toast.error("Oops! Something went wrong.");
      }
    }
  }

  async function signUpGithub() {
    try {
      const authUrl = await getAuthorisationURLWithQueryParamsAndSetState({
        thirdPartyId: "github",
        frontendRedirectURI: `${process.env.REACT_APP_WEBSITE_DOMAIN}/sdk/auth/callback/github`,
      });
      window.location.assign(authUrl);
    } catch (err) {
      if (err.isSuperTokensGeneralError === true) {
        toast.error(err.message);
      } else {
        toast.error("Oops! Something went wrong.");
      }
    }
  }

  const handleSign = async (data) => {
    setLoading(true);
    try {
      let response = await emailPasswordSignIn({
        formFields: [
          {
            id: "email",
            value: data.email,
          },
          {
            id: "password",
            value: data.password,
          },
        ],
      });
      if (response) {
      }
      if (response.status === "OK") {
        setLoading(false);
        if(logFrom==='app'){
        navigate(appBilling);
        }
        else{
        navigate(sdkAccount);
        }
        toast.success("Login successful. Welcome back!");
        window.location.reload();
      }
    } catch (err) {
      if (err.isSuperTokensGeneralError === true) {
        setLoading(false);
        toast.error("Oops! Something went wrong.");
      } else if (err.status === 401) {
        setLoading(false);
        toast.error("Login failed. Please check your credentials.");
      }
    }
  };

  return (
    <div className="relative h-screen w-screen flex items-center justify-center bg-primary-color-dark overflow-hidden">
      <div
        className="flex items-center justify-center w-[200px] h-[200px] sm:w-[500px] sm:h-[500px] md:w-[650px] md:h-[650px] rounded-full"
        style={{
          background:
            "radial-gradient(circle at 50% 50%, #997529 0%, #985F2A 0%, #A1792D 0%, #985F2A 26%, #966125 41%, #825520 54%, #684323 71%, #2D2A2A 82%, #2D2A2A 100%)",
          boxShadow: "4px 6px 95px 29px #8f681b inset",
          animation: "colorChange1 5s infinite alternate",
        }}
      />

      <div className="absolute flex flex-row justify-end h-full w-screen">
        <div className="h-full w-full sm:w-1/2 backdrop-blur-[45px]">
          <div className="relative flex flex-row items-center justify-center h-full w-full overflow-y-scroll scrollable-content">
            <div className="flex flex-col items-center justify-center">
              <div className="flex justify-center flex-grow">
                <Link to={sdkHome}>
                  <img
                    src={logo_square_without_name}
                    alt="logo"
                    className="object-cover h-20 w-20"
                  />
                </Link>
              </div>
              <h1 className="text-white text-3xl md:text-4xl mt-5 md:mt-8 font-primary font-bold ">
                Log in
              </h1>
              <p className="text-white text-base md:text-lg font-primary mt-4">
                Welcome to{" "}
                <span className="border ml-2 px-2 py-[2px] rounded-xl bg-secondary-color font-bold heading shadow-top-left-bottom-right">
                  Autoflow
                </span>
              </p>

              <form action="" onSubmit={handleSubmit(handleSign)}>
                <Input
                  label="Email"
                  type="text"
                  placeholder="Enter your email"
                  inputWidth="w-[350px] sm:w-[400px]"
                  register={register("email", {
                    required: "*Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i,
                      message: "Invalid email address",
                    },
                  })}
                  error={errors?.email}
                />
                <PasswordInput
                  inputWidth="w-[350px] sm:w-[400px]"
                  register={register("password", {
                    required: "*Password is required",
                    minLength: {
                      value: 5,
                      message: "Password must be at least 5 characters long",
                    },
                  })}
                  errors={errors?.password}
                />

                <div className="w-full my-3 md:my-6">
                  <Link to={sdkForgotPwd}>
                    <p className="text-end text-blue-300 font-secondary hover:text-blue-200 text-sm md:text-base">
                      Forgot password
                    </p>
                  </Link>
                </div>
                <div className="">
                  <button
                    className={`btn btn-sm md:btn-md bg-primary-color border-none outline-none hover:bg-primary-color/85 w-[350px] sm:w-[400px] font-secondary text-sm md:text-base ${
                      loading && "btn-disabled"
                    }`}
                  >
                    {loading ? (
                      <span className="loading loading-sm md:loading-lg loading-bars  text-warning"></span>
                    ) : (
                      "Sign in"
                    )}
                  </button>
                </div>
              </form>

              {/* <div className="flex flex-row items-center justify-center w-[300px] mx-auto my-6">
                <div className="border-t border-white w-1/4"></div>
                <div className="text-white font-primary font-bold mx-4 text-xs">
                  or login with
                </div>
                <div className="border-t border-white w-1/4"></div>
              </div> */}

              {/* <div className="flex flex-row gap-2">
                <div>
                  <button
                    className="btn btn-sm md:btn-md bg-black hover:bg-gray-900 border-gray-600 hover:border-gray-600 outline-none  w-[160px] sm:w-[195px] font-secondary text-sm md:text-base text-white"
                    onClick={signUpGoogle}
                  >
                    <IoLogoGoogle />
                    Google
                  </button>
                </div>
                <div>
                  <button
                    className="btn btn-sm md:btn-md bg-black hover:bg-gray-900 border-gray-600 hover:border-gray-600 outline-none  w-[160px] sm:w-[195px] font-secondary text-sm md:text-base text-white"
                    onClick={signUpGithub}
                  >
                    <FaGithub />
                    Github
                  </button>
                </div>
              </div> */}
              <div className="text-center mt-10">
                <p className="text-white/55 font-secondary text-sm md:text-base">
                  Don't have an account?{" "}
                  <Link
                    to={logFrom?appSignUp:sdkSignUp}
                    className="text-blue-300 font-secondary font-bold hover:text-blue-200"
                  >
                    Sign up
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
