import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { profile2, updateProfile } from "../../../api/services/apiService";
const Profile = () => {
  const [userData, setUserData] = useState();
  const [editButton, setEditButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(userData && userData.name);
  const nameInputRef = useRef(null);

  useEffect(() => {
    setLoading(true);
    profile2()
      .then((res) => {
        setLoading(false);
        res && setUserData(res.ProfileData);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (editButton && nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, [editButton]);

  const handleSave = () => {
    let payload = {
      name: name,
    };
    updateProfile(payload)
      .then((res) => {
        toast.success(res.detail);
        setEditButton(false);
      })
      .catch((err) => console.log(err, "error"));
  };

  const renderInputField = (label, value, readOnly = true, onchange) => {
    const isNameField = label === "Name";
    const textColorClass = isNameField ? "text-white" : "text-gray-400";

    return (
      <div className="flex flex-col justify-center mt-4 sm:w-2/3 md:w-1/2">
        <label className="form-control w-full">
          <div className="label -mb-1">
            <span className={`label-text font-secondary ${textColorClass}`}>
              {label}
            </span>
          </div>
          <input
            type="Text"
            value={value}
            readOnly={readOnly}
            onChange={onchange}
            className={`input input-sm md:input-md bg-transparent border-white/50 outline-none focus:border-white/50 ${textColorClass} font-secondary`}
            ref={isNameField ? nameInputRef : null} // Set the ref for the name input field
          />
        </label>
      </div>
    );
  };
  return (
    <div className="flex flex-col space-y-10 mt-10 bg-black pt-5 px-2 sm:px-10 w-full font-secondary">
      <div className="pb-10 z-[800] border border-[#5352529a] bg-black rounded-lg h-auto">
        <div className="p-4 md:p-10">
          <h1 className="text-white text-start text-xl md:text-3xl font-primary font-bold">
            My{" "}
            <span className="underline decoration-2 md:decoration-4 decoration-primary-color underline-offset-4">
              Profile
            </span>
          </h1>

          {loading ? (
            <div className="flex items-center justify-center my-20">
              <span className="loading loading-bars loading-lg text-warning ml-2"></span>
            </div>
          ) : (
            <div className="mt-10 w-full">
              {renderInputField(
                "Name",
                editButton ? name : userData ? userData.name : "",
                !editButton,
                (e) => setName(e.target.value)
              )}
              {renderInputField("Email", userData ? userData.email : "", true)}
              {renderInputField(
                "Organisation",
                userData ? userData.organisation : "",
                true
              )}
              {renderInputField(
                "Membership Type",
                userData ? userData.membership_type : "",
                true
              )}
              <div className="flex flex-row items-center justify-end md:justify-center gap-x-2 mt-10">
                {editButton && (
                  <button
                    className="btn btn-xs sm:btn-sm md:btn-md btn-warning px-5 font-secondary font-bold text-xs md:text-base"
                    onClick={handleSave}
                  >
                    Save profile
                  </button>
                )}

                <button
                  className={`btn btn-xs sm:btn-sm md:btn-md ${
                    editButton ? "btn-error" : "btn-warning"
                  } px-5 font-secondary font-bold text-xs md:text-base`}
                  onClick={() => setEditButton(!editButton)}
                >
                  {editButton ? "Cancel" : "Edit profile"}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
