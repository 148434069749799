import React, { useEffect, useState } from "react";

import { initializePaddle } from "@paddle/paddle-js";
import { FaDollarSign } from "react-icons/fa6";
import { getEmailFromSession } from "../../../utils/utlilityFunctions/GetEmailFromSessions";
import { getBillingDetails, postTransaction } from "../../../api/services/apiService";
import PlanCard from "./components/PlanCard";
import { formatDate } from "../../../utils/utlilityFunctions/formatDate";
import { formatNextMonth } from "../../../utils/utlilityFunctions/formatNextMonth";
import { IoCheckmarkDoneOutline } from "react-icons/io5";
import { BsQuestionSquare } from "react-icons/bs";

function Billing({logFrom}) {
  const [billingData, setBillingData] = useState();
  const [paddle, setPaddle] = useState();
  const [email, setEmail] = useState();
  useEffect(() => {
    const getEmail = async () => {
      let email = await getEmailFromSession();
      setEmail(email);
    };
    getEmail();
  }, []);

  useEffect(() => {
    getBillingDetails()
      .then((res) => {
        setBillingData(res);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    let environment = process.env.REACT_APP_ENV === "local" ? "sandbox" : null;
    let token = process.env.REACT_APP_PADDLE_CLIENT_TOKEN;

    initializePaddle({
      // environment: process.env.ENV === 'local' ? "sandbox" : null,
      environment: environment,
      token: token, //PADDLE CLIENT TOKEN

      eventCallback: function (data) {
        if (data.name === "checkout.completed") {
          let payload = {
            transactionId: data.data.transaction_id,
            status: "COMPLETED",
            email: data.data.customer.email,
          };
          postTransaction(payload)
            .then((res) => {
              window.location.reload();
            })
            .catch((err) => {
              console.log("Transaction Post Error", err);
            });
        }
        if (data.name === "checkout.payment.failed") {
          let payload = {
            transactionId: data.data.transaction_id,
            status: "FAILED",
            email: data.data.customer.email,
          };
          postTransaction(payload)
            .then((res) => {})
            .catch((err) => {
              console.log("Transaction Post Error", err);
            });
        }
      },
    }).then((paddleInstance) => {
      if (paddleInstance) {
        setPaddle(paddleInstance);
      }
    });
  }, []);

  const handleBasicPlan = () => {
    paddle?.Checkout.open({
      settings: {
        theme: "dark",
      },
      customer: {
        email: email,
      },
      items: [
        {
          priceId: process.env.REACT_APP_PADDLE_PRODUCT_ID_AUTOFLOW_BASIC,
          quantity: 1,
        },
      ],
    });
  };

  return (
    <div className="flex flex-col space-y-10 mt-10 mb-5 bg-black pt-5 px-2 sm:px-10 w-full font-secondary">
      <div className="pb-10 z-[800] border border-[#5352529a] bg-black rounded-lg h-auto">
        <div className="p-1 sm:p-4 md:p-10">
          <div className="px-2 pt-1">
            <h1 className="text-white text-start text-xl md:text-3xl font-primary font-bold">
              <span className="underline decoration-2 md:decoration-4 decoration-primary-color underline-offset-4">
                Billing
              </span>
            </h1>
            <p className="font-secondary mt-2 text-white/60 text-[10px] sm:text-lg">
              Manage your billing and payment details
            </p>
          </div>
          <div className="flex flex-col mt-8">
            <div className="m-2 md:mb-8">
              <h2 className="text-white  sm:text-xl font-primary font-bold mb-1 md:mb-4">
                Current Subscription:
              </h2>
              {billingData && billingData.detail !== "Free membership" && (
                <p className="font-secondary text-white text-xs sm:text-base">
                  {`Your account is billed ${
                    billingData &&
                    (billingData.billing_cycle
                      ? billingData.billing_cycle === "1month"
                        ? "monthly"
                        : billingData.billing_cycle
                      : "monthly")
                  } and next payment is due to`}{" "}
                  <span className="font-bold text-primary-color">
                    {billingData &&
                      (billingData.subscription.next_billing_at
                        ? formatDate(billingData.subscription.next_billing_at)
                        : formatNextMonth)}
                  </span>
                </p>
              )}
            </div>

            {billingData ? (
              billingData.detail === "Free membership" ? (
                <PlanCard
                  title="Free Plan"
                  description="Free for individual use up to 1000 invocations"
                  price="$0"
                  tenure="month"
                  invocationsUsed={
                    billingData &&
                    billingData.invocation_count &&
                    billingData.invocation_count
                  }
                  totalInvocations="1000"
                  handleClick={handleBasicPlan}
                />
              ) : (
                <PlanCard
                  title="AutoFlow Basic"
                  description="for Business and start ups"
                  price="$50"
                  tenure="month"
                  billing_cycle={
                    billingData.subscription.billing_cycle &&
                    billingData.subscription.billing_cycle
                  }
                  invocationsUsed={
                    billingData.invocation_count && billingData.invocation_count
                  }
                  totalInvocations="5000"
                  next_billing_at={
                    billingData.subscription.next_billing_at &&
                    billingData.subscription.next_billing_at
                  }
                  update_payment_url={
                    billingData.subscription.update_payment_url &&
                    billingData.subscription.update_payment_url
                  }
                  cancel_subscription_url={
                    billingData.subscription.cancel_subscription_url &&
                    billingData.subscription.cancel_subscription_url
                  }
                />
              )
            ) : (
              <span className="loading loading-bars loading-lg text-warning ml-2"></span>
            )}
          </div>
          <div className="border-b border-[#5352529a] my-10" />
          {
           true? (<div
            className="mx-auto mt-16 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:mt-20 sm:gap-y-0 lg:max-w-6xl 
          lg:grid-cols-3"
          >
            {/* card0 */}
            <div className="bg-black/40 sm:mx-8 lg:mx-0 rounded-t-3xl sm:rounded-b-none lg:rounded-bl-3xl lg:rounded-tr-none rounded-3xl p-8 ring-1 ring-zinc-800 backdrop-blur-md">
              <h4 className="text-zinc-400 text-3xl font-display font-semibold leading-loose">
                Free{" "}
                <span className="text-sm text-primary-color-light/70 font-normal">
                  (For Hobbyists)
                </span>
              </h4>
              <div className="mt-4 flex items-baseline gap-x-2">
                <h1 className="text-zinc-400 text-5xl font-semibold tracking-tight">
                  $0
                </h1>
                <span className="text-zinc-400 font-heading">/ month</span>
              </div>
              <p className="text-zinc-400 mt-6 text-sm">
                For testing simple web apps and learn about the platform
              </p>
              <ul className="text-zinc-400 mt-8 space-y-3 text-sm font-display">
                <li className="flex font-semibold items-center gap-x-3">
                  What's included:
                </li>
                <li className="flex items-center gap-x-3">
                  <IoCheckmarkDoneOutline /> 1 User
                </li>
                <li className="flex items-center gap-x-3">
                  <IoCheckmarkDoneOutline />
                  Unlimited Tests
                </li>
                <li className="flex items-center gap-x-3">
                  <IoCheckmarkDoneOutline />
                  50 Cloud Test Runs
                </li>
                <li className="flex items-center gap-x-3">
                  <IoCheckmarkDoneOutline />
                  Unlimited Test Suites
                </li>
                <div class="tooltip" data-tip="hello">
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline />
                    Screenshots <BsQuestionSquare />
                  </li>
                </div>
                <br/>
                <div class="tooltip" data-tip="hello">
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline />
                    Video Recordings <BsQuestionSquare />
                  </li>
                </div>
                <li className="flex items-center gap-x-3">
                  <IoCheckmarkDoneOutline />
                  7 Days of Test History
                </li>
                <li className="flex items-center gap-x-3">
                  <IoCheckmarkDoneOutline />
                  Community & Email Support
                </li>
              </ul>
              <button
                // onClick={() => handlePriceSubmit("Basic")}
                className="relative mt-8 inline-flex w-full flex-shrink-0 items-center justify-center rounded-xl border px-3 py-2 font-heading text-sm font-medium transition border-zinc-500/50 bg-zinc-600/10 text-zinc-400 hover:border-zinc-500/80"
              >
                Download App
              </button>
            </div>

            {/* card1 */}
            <div className="relative bg-primary-color-light/80 shadow-2xl rounded-3xl p-8 py-10 ring-1 ring-zinc-800 backdrop-blur-md z-20">
              <h4 className="text-zinc-950 text-3xl font-display font-semibold leading-loose">
                Basic{" "}
                <span className="text-sm text-primary-color-dark font-normal">
                  (For startups)
                </span>
              </h4>
              <div className="mt-4 flex items-baseline gap-x-2">
                <h1 className="text-zinc-400 text-5xl font-semibold tracking-tight">
                  $250
                </h1>
                <span className="text-zinc-400 font-heading">/ month</span>
              </div>
              <p className="text-zinc-400 mt-6 text-sm">
                Everything you need to get started with testing, perfect for
                individuals and small teams.
              </p>
              <ul className="text-zinc-400 mt-8 space-y-3 text-sm font-display">
                <li className="flex font-semibold items-center gap-x-3">
                  Everything in FREE plus:
                </li>
                <li className="flex items-center gap-x-3">
                  <IoCheckmarkDoneOutline /> 5 Users
                </li>
                <li className="flex items-center gap-x-3">
                  <IoCheckmarkDoneOutline /> 2000 Cloud Test Runs
                </li>
                <li className="flex items-center gap-x-3">
                  <IoCheckmarkDoneOutline /> 2 Parallel Runs
                </li>
                <div class="tooltip" data-tip="hello">
                <li className="flex items-center gap-x-3">
                  <IoCheckmarkDoneOutline /> Scheduled Runs
                </li>
                </div>
                <br/>
                <div class="tooltip" data-tip="hello">
                <li className="flex items-center gap-x-3">
                  <IoCheckmarkDoneOutline /> CI/CD Integrations
                </li>
                </div>
                <br/>
                <div class="tooltip" data-tip="hello">
                <li className="flex items-center gap-x-3">
                  <IoCheckmarkDoneOutline /> Slack Notifications
                </li>
                </div>
                <br/>
                <div class="tooltip" data-tip="hello">
                <li className="flex items-center gap-x-3">
                  <IoCheckmarkDoneOutline /> Email Notifications
                </li>
                </div>
                <br/>
                <div class="tooltip" data-tip="hello">
                <li className="flex items-center gap-x-3">
                  <IoCheckmarkDoneOutline /> Environments
                </li>
                </div>
                <br/>
                <div class="tooltip" data-tip="hello">
                <li className="flex items-center gap-x-3">
                  <IoCheckmarkDoneOutline /> Inbox for e-mail testing
                </li>
                </div>
                <br/>
                <div class="tooltip" data-tip="hello">
                <li className="flex items-center gap-x-3">
                  <IoCheckmarkDoneOutline /> Webhooks
                </li>
                </div>
                <br/>
                <div class="tooltip" data-tip="hello">
                <li className="flex items-center gap-x-3">
                  <IoCheckmarkDoneOutline /> API Access
                </li>
                </div>
                <br/>
                <div class="tooltip" data-tip="hello">
                <li className="flex items-center gap-x-3">
                  <IoCheckmarkDoneOutline />
                  500 AI Suggestions
                </li>
                </div>
                <br/>
                <div class="tooltip" data-tip="hello">
                <li className="flex items-center gap-x-3">
                  <IoCheckmarkDoneOutline />
                  1000 AI Steps
                </li>
                </div>
                <br/>
                <div class="tooltip" data-tip="hello">
                <li className="flex items-center gap-x-3">
                  <IoCheckmarkDoneOutline />
                  24/7 Support
                </li>
                </div>
              </ul>
              <button
                // onClick={() => handlePriceSubmit("Basic")}
                className="relative mt-8 inline-flex w-full flex-shrink-0 items-center justify-center rounded-xl border px-3 py-2 font-heading text-sm font-medium transition border-zinc-500/50 bg-zinc-600/10 text-black font-bold hover:border-zinc-500/80"
              >
                Subscribe Now
              </button>
            </div>

            {/* card2 */}
            <div className="bg-black/40 sm:mx-8 lg:mx-0 rounded-t-3xl sm:rounded-b-none lg:rounded-br-3xl lg:rounded-tl-none rounded-3xl p-8 ring-1 ring-zinc-800 backdrop-blur-md border-[5px] border-[#F29D20]">
              <div className="flex flex-row items-center justify-between">
                <h1 className="text-zinc-200 text-3xl font-display font-semibold leading-loose">
                  Pro{" "}
                  <span className="text-zinc-400 text-sm font-normal">
                    (For SMEs & Enterprises)
                  </span>
                </h1>

                <div className="absolute top-6 right-6 badge badge-warning badge-md gap-2 font-heading font-medium">
                  ✦ Most popular
                </div>
              </div>
              <div className="mt-4 flex items-baseline gap-x-2">
                <h1 className="text-zinc-400 text-5xl font-semibold tracking-tight">
                  Talk to Us
                </h1>
                {/* <span className="text-zinc-600 font-heading">/ month</span> */}
              </div>

              <p className="text-zinc-400 mt-6 text-sm">
                Crafted for teams and enterprises seeking an unparalleled,
                privacy-friendly testing experience.
              </p>
              <ul className="text-zinc-400 mt-8 space-y-3 text-sm font-display">
                <li className="flex font-semibold items-center gap-x-3">
                  Everything in BASIC plus:
                </li>
                <li className="flex items-center gap-x-3">
                  <IoCheckmarkDoneOutline /> Up to 48x faster testing via parallel test execution
                </li>
                <li className="flex items-center gap-x-3">
                  <IoCheckmarkDoneOutline /> Custom Seat Numbers
                </li>
                <li className="flex items-center gap-x-3">
                  <IoCheckmarkDoneOutline />
                  Up to Unlimited test history
                </li>
                <li className="flex items-center gap-x-3">
                  <IoCheckmarkDoneOutline />
                  Exclusive Customer Support
                </li>
                <li className="flex items-center gap-x-3">
                  <IoCheckmarkDoneOutline />
                  On-Premise Deployment
                </li>
                <li className="flex items-center gap-x-3">
                  <IoCheckmarkDoneOutline />
                  Customization Support for specific use-cases
                </li>
              </ul>
              <button
                // onClick={() => handlePriceSubmit("Pro")}
                className="relative mt-8 inline-flex w-full flex-shrink-0 items-center justify-center rounded-xl border px-3 py-2 font-heading text-sm font-medium transition border-zinc-500/50 bg-zinc-600/10 text-zinc-400 hover:border-zinc-500/80"
              >
                Contact Sales
              </button>
            </div>
          </div>):(
            <div className="py-4 flex flex-col sm:flex-row justify-center sm:justify-start gap-8">
            {/* {billingData ? (
              billingData.detail === "Free membership" && )}
               */}
            <div className="card border border-[#5352529a] w-full sm:w-96 relative">
              {billingData && billingData.detail === "Paid membership" && (
                <p className="absolute top-2 right-2 text-white border ml-2 px-2 py-[2px] rounded-xl bg-secondary-color font-bold heading shadow-top-left-bottom-right">
                  Current Plan
                </p>
              )}

              <div className="card-body">
                <h2 className="card-title text-primary-color text-xl sm:text-2xl font-bold">
                  AutoFlow Basic
                </h2>
                <p className="font-secondary font-light text-sm sm:text-base text-white/55">
                  for Business and start ups
                </p>
                <p className="sm:text-2xl font-bold mb-2 mt-5 flex flex-col">
                  <div className="flex flex-row items-center text-white">
                    <FaDollarSign />
                    <span className="text-4xl sm:text-6xl join">50</span>
                  </div>
                  <div className="text-sm sm:text-base px-2 text-white">
                    / month
                  </div>
                </p>

                <div className="card-actions py-2 justify-start">
                  <button
                    onClick={
                      billingData && billingData.detail === "Paid membership"
                        ? null
                        : handleBasicPlan
                    }
                    className={`btn btn-sm w-40 btn-outline btn-warning sm:btn-md font-secondary ${
                      billingData &&
                      billingData.detail === "Paid membership" &&
                      "cursor-not-allowed hover:bg-black no-animation"
                    }`}
                  >
                    {billingData && billingData.detail === "Free membership"
                      ? "Buy now"
                      : "Explore"}
                  </button>
                </div>
              </div>
              <div className="bg-gray-800 h-32 p-4 px-8 rounded-b-2xl">
                <h3 className="text-white font-primary font-bold mb-1">
                  Features:
                </h3>

                <p className="mb-1 text-sm sm:text-base font-semibold text-white">
                  - 5,000{" "}
                  <span className="text-primary-color font-semibold">
                    autoflow()
                  </span>{" "}
                  invocations
                </p>
                <p className="mb-1 text-sm sm:text-base font-semibold text-white">
                  - 5 users
                </p>
              </div>
            </div>

            <div className="card border border-[#5352529a] w-full sm:w-96">
              <div className="card-body">
                <h2 className="card-title text-primary-color text-xl sm:text-2xl font-bold">
                  AutoFlow Pro
                </h2>
                <p className="font-secondary font-light text-sm sm:text-base text-white/55">
                  for large Business and Enterprises
                </p>
                <p className="text-2xl sm:text-5xl font-bold mb-2 mt-5 flex flex-col">
                  <div className="flex flex-row items-center text-white">
                    Let's talk
                  </div>
                </p>

                <div className="card-actions justify-start py-2">
                  <button
                    onClick={() => {
                      window.open(
                        `https://mail.google.com/mail/?view=cm&fs=1&tocontact@autoflowapp.com}`,
                        "_blank"
                      );
                    }}
                    className="btn btn-sm w-40 btn-outline btn-warning sm:btn-md font-secondary"
                  >
                    Contact Sales
                  </button>
                </div>
              </div>
              <div className="bg-gray-800 h-32 p-4 px-8 rounded-b-2xl">
                <h3 className="text-white font-primary font-bold mb-1">
                  Features:
                </h3>

                <p className="mb-1 text-sm sm:text-base font-semibold text-white">
                  - Custom{" "}
                  <span className="text-primary-color font-semibold">
                    autoflow()
                  </span>{" "}
                  invocations
                </p>
                <p className="mb-1 text-sm sm:text-base font-semibold text-white">
                  - Unlimited users
                </p>
              </div>
            </div>
          </div>
          )
          }
          
        </div>
      </div>
    </div>
  );
}

export default Billing;
