import React, { useState } from "react";
import {
  IoArrowForwardSharp,
  IoCloseCircleSharp,
  IoDiamondOutline,
} from "react-icons/io5";
import { LuNewspaper, LuScrollText } from "react-icons/lu";
import { MdOutlineFileDownload } from "react-icons/md";
import { Link } from "react-router-dom";
import { logo } from "../../../assets";
import {
  appDownloads,
  appHome,
  appPricing,
  sdkHome,
  appProduct,
  appDev,
  appQA,
  appFeature,
  appWhyUs
} from "../../../routes/routes";

const AppMobileNav = ({ openMenu, setOpenMenu, location, productHiglight }) => {
  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };
  return openMenu ? (
    <div className="absolute top-0 right-0 w-screen h-screen bg-zinc-900 flex flex-col justify-between bg-opacity-100 font-display backdrop-blur-none font-bold">
      <div>
        <div>
          <div className="flex flex-row items-center justify-between px-3">
            <Link to={appHome}>
              <div className="my-5 relative cursor-pointer">
                <img src={'https://ik.imagekit.io/du5yq7zec/Landing%20Page/autoflowLogoWhite.png?updatedAt=1721131360109'} alt="Logo" className="h-12" />
                <div class="badge absolute bg-primary-color bottom-[-12px] right-[-20px]">Studio</div>
              </div>
            </Link>
            <button
              className="btn btn-ghost"
              onClick={() => setOpenMenu(false)}
            >
              <IoCloseCircleSharp className="text-4xl text-white" />
            </button>
          </div>
        </div>
        <div className="mt-5 flex flex-row items-center justify-end gap-x-1 mr-1">
          {/* <Link to={sdkHome} target="_blank">
            <button
              className="btn btn-sm btn-ghost border font-display font-normal border-secondary-color-light/20 bg-secondary-color-dark text-primary-color-light/80 rounded-2xl hover:border-secondary-color-light/50
            "
            >
              SDK
            </button>
          </Link> */}
          <Link to={appDownloads}>
            <button
              className="btn btn-sm btn-ghost border font-display font-normal border-secondary-color-light/20 bg-primary-color-dark text-primary-color-light/80 rounded-2xl hover:border-secondary-color-light/50
            "
            >
              Download app
              <IoArrowForwardSharp />
            </button>
          </Link>
        </div>
        <div className="flex flex-col items-start pt-5">
          {/* <Link to={appDownloads} onClick={() => setOpenMenu(false)}>
            <button
              className={`btn btn-sm btn-ghost text-base font-display font-normal  ${
                location === appDownloads ? " text-yellow-600" : "text-white/50"
              }`}
            >
              <MdOutlineFileDownload /> Download
            </button>
          </Link> */}
            <div className="accordion">
              <div className="accordion-item">
                <div
                  role="button"
                  tabIndex={0}
                    className={`btn btn-sm btn-ghost text-base font-display font-normal  ${productHiglight() ? " text-yellow-600" : "text-white/50"
                    }`}
                  onClick={() => toggleSection('product')}
                >
                  <LuScrollText />
                  Product
                  <svg
                    className={`h-5 w-5 transition-transform ${openSection === 'product' ? 'rotate-180' : ''}`}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                {openSection === 'product' && (
                  <div className="accordion-content  p-4 shadow mt-1">
                    <div className="flex flex-col  mb-2">
                      <div className="text-white font-bold text-sm mb-2">About Product</div>
                      <Link to={appFeature} onClick={() => setOpenMenu(false)}>
                        <button
                          className={`relative z-10 flex flex-shrink-0 border border-transparent px-6 py-2 text-sm transition hover:text-yellow-600/80 ${location.pathname === appFeature
                            ? "border-primary-color/20 text-yellow-600"
                            : "text-zinc-400"
                            }`}
                        >
                          Features
                        </button>
                      </Link>
                      <Link to={'https://autoflow.featurebase.app/'} onClick={() => setOpenMenu(false)}>
                        <button
                          className={`relative z-10 flex flex-shrink-0 border border-transparent px-6 py-2 text-sm transition hover:text-yellow-600/80 ${location.pathname === appWhyUs
                            ? "border-primary-color/20 text-yellow-600"
                            : "text-zinc-400"
                            }`}
                        >
                          Roadmap
                        </button>
                      </Link>
                    </div>
                    <div className="flex flex-col ">
                      <div className="text-white font-bold text-sm mb-2">Solutions</div>
                      <Link to={appQA} onClick={() => setOpenMenu(false)}>
                        <button
                          className={`relative z-10 flex flex-shrink-0 border border-transparent px-6 py-2 text-sm transition hover:text-yellow-600/80 ${location.pathname === appQA
                            ? "border-primary-color/20 text-yellow-600"
                            : "text-zinc-400"
                            }`}
                        >
                          For QAs
                        </button>
                      </Link>
                      <Link to={appDev} onClick={() => setOpenMenu(false)}>
                        <button
                          className={`relative z-10 flex flex-shrink-0 border border-transparent px-6 py-2 text-sm transition hover:text-yellow-600/80 ${location.pathname === appDev
                            ? "border-primary-color/20 text-yellow-600"
                            : "text-zinc-400"
                            }`}
                        >
                          For Developers
                        </button>
                      </Link>
                      <Link to={appProduct} onClick={() => setOpenMenu(false)}>
                        <button
                          className={`relative z-10 flex flex-shrink-0 border border-transparent px-6 py-2 text-sm transition hover:text-yellow-600/80 ${location.pathname === appProduct
                            ? "border-primary-color/20 text-yellow-600"
                            : "text-zinc-400"
                            }`}
                        >
                          For Product Managers
                        </button>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>

          {/* <Link>
            <button
              className={`btn btn-sm btn-ghost text-base font-display font-normal cursor-not-allowed text-white/50 `}
            >
              <LuNewspaper /> Blog{" "}
              <span className="ml-2 mt-0.5 text-xs border border-secondary-color-light/10 rounded-md px-1">
                Soon!
              </span>
            </button>
          </Link> */}
          <Link to={sdkHome} target="_blank">
            <button
              className={`btn btn-sm btn-ghost text-base font-display font-normal text-white/50 `}
            >
              <LuNewspaper />
              SDK
            </button>
          </Link>
          <button className={`btn btn-sm btn-ghost text-base font-display font-normal text-white/50`}>
          <LuNewspaper />
                <a href="https://docs.autoflow.tools" target="_blank">Documentation</a>
              </button>
          <Link to="https://blog.autoflow.tools" target="_blank">
            <button
              className={`btn btn-sm btn-ghost text-base font-display font-normal text-white/50`}
            >
              <LuScrollText />
              Blog
            </button>
          </Link>
          <Link to={appPricing} onClick={() => setOpenMenu(false)}>
            <button
              className={`btn btn-sm btn-ghost text-base font-display font-normal  ${location === appPricing ? " text-yellow-600" : "text-white/50"
                }`}
            >
              <IoDiamondOutline /> Pricing
            </button>
          </Link>
        </div>
      </div>
    </div>
  ) : null;
};

export default AppMobileNav;
